/*
 * A data structure for maintaining the conversation state.
 */
import { initializeHistory } from "./history";
import { retrieveMessageSpec } from "./messageSpec";
import { getParent } from "./appHierarchy";
import { fetchJsonUsingGet, fetchTextUsingGet } from "./fetchUtils";
import { clearPriorResults } from "./priorResults";
import { getCurrentId } from "./sharedFunctions";

/*** API: LOADING A NEW POSITION */

export async function initializeConversationState() {
  const position = buildPosition(0, 0, 0, 0);
  var conversationState = {
    position: position,
    priorResults: {},
    catalog: await fetchCatalog(),
  };
  conversationState = await loadPosition(position, conversationState);
  initializeHistory();
  return conversationState;
}

// This is used when an app requests a "one-shot" interaction without other context
export async function loadConversationState(position) {
  var conversationState = {
    catalog: await fetchCatalog(),
    position: position,
    priorResults: {},
  };
  initializeHistory();

  // Load the specs
  const levels = ["app", "activity", "task", "request"];
  for (const level of levels) {
    var element = await retrieveMessageSpec(level, conversationState);
    conversationState[level] = element;
  }

  console.log("loadConversationState:");
  console.log(conversationState);
  return conversationState;
}

export async function loadPosition(position, conversationState) {
  // Create a new object, which is required to trigger re-rendering the UI
  const newConversationState = {
    catalog: conversationState.catalog,
    persona: conversationState.persona,
    priorResults: conversationState.priorResults,
    userInput: conversationState.userInput,
  };

  // Store the position
  newConversationState.position = position;

  // Update the userInput if needed
  if (newConversationState.position.task !== conversationState.position.task) {
    newConversationState.userInput = await getCurrentUserInput(
      newConversationState
    );
  }

  // Load the specs
  const levels = ["app", "activity", "task", "request"];
  for (const level of levels) {
    var element = await retrieveMessageSpec(level, newConversationState);
    newConversationState[level] = element;
  }

  return newConversationState;
}

export async function loadApp(id, conversationState) {
  // Clear the prior results & the persona
  conversationState.priorResults = {};
  conversationState.persona = {};

  const position = buildPosition(id, 0, 0, 0);
  return await loadPosition(position, conversationState);
}

/*** API: CHANGING REQUESTS WITHIN THE CURRENT TASK ***/

export async function advanceRequest(conversationState) {
  const length = conversationState.task.children.length;
  var requestIndex = conversationState.position.request;

  if (requestIndex < length - 1) {
    requestIndex += 1;

    const position = {
      ...conversationState.position,
      request: requestIndex,
    };

    const newProgramStatus = await loadPosition(position, conversationState);
    return newProgramStatus;
  } else {
    console.error(
      "(advanceRequest) Tried to advance to the next request when already on the last one."
    );
    return conversationState;
  }
}

// ISSUE: Do I really want to clear all priorResults during this action?
//    Or is it only those for the current task?
export async function restartRequests(conversationState) {
  var position = conversationState.position;
  position.request = 0;
  // Clear prior results
  conversationState.priorResults = {};
  const newConversationState = await loadPosition(position, conversationState);
  clearPriorResults(newConversationState);
  initializeHistory();
  return newConversationState;
}

export function isLastRequest(conversationState) {
  const task = conversationState.task;
  return (
    isEmpty(task) ||
    !task.children ||
    conversationState.position.request >= task.children.length - 1
  );
}

export function isFirstRequest(conversationState) {
  return conversationState.position.request === 0;
}

export function buildPosition(app, activity, task, request) {
  return {
    app: app,
    activity: activity,
    task: task,
    request: request,
  };
}

/*** UTILITY ACCESSORS ***/

// eslint-disable-next-line no-unused-vars
export function getCurrentModelResponse(conversationState) {
  return "<not yet implemented>";
}

export async function getCurrentUserInput(conversationState) {
  const persona = conversationState.persona && conversationState.persona.id;
  const task = getCurrentId("task", conversationState);
  if (persona && task) {
    const endpoint = `api/v1/user-input/${persona}/${task}`;
    return await fetchTextUsingGet(endpoint);
  }
  return "";
}

export function getCurrentIndex(level, conversationState) {
  return conversationState.position[level];
}

export function getSiblings(level, conversationState) {
  const parent = getParent(level);
  return parent ? conversationState[parent]["children"] : [];
}

/** Retrieval functions ***/

/*
 * Retrieve an array of persona objects of the form {id: <id>, title: <title>}
 */
export async function getPersonas(conversationState) {
  const index = conversationState.position.app;
  const app = conversationState.catalog.children[index];
  const url = `api/v1/personas/${app}`;
  return await fetchJsonUsingGet(url);
}

export async function fetchCatalog() {
  const endpoint = "api/v1/catalog";
  const data = await fetchJsonUsingGet(endpoint);
  return data;
}

/*** Utilities ***/
export function isEmpty(object) {
  return Object.keys(object).length === 0;
}
