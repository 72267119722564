/*
 * A data structure for tracking the current course position.
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBackward,
  faForward,
  faPlay,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";

import {
  advanceRequest,
  isFirstRequest,
  isLastRequest,
  loadPosition,
  restartRequests,
} from "@chipcleary/coachforge-engine";
import ConversationStateContext from "components/ConversationStateContext";

import { useContext } from "react";

import { isEmpty } from "utils/utils";

export function RestartRequestsButton() {
  const [conversationState, setconversationState] = useContext(
    ConversationStateContext
  );
  var isDisabled = isFirstRequest(conversationState);

  async function clickAction() {
    await setconversationState(await restartRequests(conversationState));
  }

  return (
    <button
      className="icon-button title-bar-button float-left-button restart-requests-button"
      onClick={clickAction}
      disabled={isDisabled}
    >
      {" "}
      <FontAwesomeIcon icon={faBackward} />{" "}
    </button>
  );
}

export function RerunCurrentRequestButton() {
  const [conversationState, setconversationState] = useContext(
    ConversationStateContext
  );
  var isDisabled = isEmpty(conversationState.request);

  async function clickAction() {
    const rerunconversationState = await loadPosition(
      conversationState.position,
      conversationState
    );
    await setconversationState(rerunconversationState);
  }

  return (
    <button
      className="icon-button  title-bar-button float-left-button"
      onClick={clickAction}
      disabled={isDisabled}
    >
      {" "}
      <FontAwesomeIcon icon={faRedo} />{" "}
    </button>
  );
}

export function RunNextRequestButton() {
  const [conversationState, setconversationState] = useContext(
    ConversationStateContext
  );
  var isDisabled = isLastRequest(conversationState);

  async function clickAction() {
    await setconversationState(await advanceRequest(conversationState));
  }

  return (
    <button
      className="icon-button title-bar-button"
      onClick={clickAction}
      disabled={isDisabled}
    >
      {" "}
      <FontAwesomeIcon icon={faPlay} />{" "}
    </button>
  );
}

// Fast forward to end of prompts for the current task.
// Pause if a prompt has a breakpoint element set (regardless of what it contains)
export function FastForwardRequestsButton() {
  const [conversationState, setconversationState] = useContext(
    ConversationStateContext
  );
  var varPosition = conversationState;
  var isDisabled = isLastRequest(conversationState);

  async function clickAction() {
    // Advance at least once
    varPosition = await advanceRequest(varPosition);
    setconversationState(varPosition);

    // Then advance more if needed to get to a task
    while (!stopFastForward(varPosition)) {
      varPosition = await advanceRequest(varPosition);
      setconversationState(varPosition);
    }
  }

  function stopFastForward(conversationState) {
    return (
      isLastRequest(conversationState) ||
      "breakpoint" in conversationState.request
    );
  }

  return (
    <button
      className="icon-button  title-bar-button"
      onClick={clickAction}
      disabled={isDisabled}
    >
      {" "}
      <FontAwesomeIcon icon={faForward} />{" "}
    </button>
  );
}
