/*
 * Provide a history data type
 *
 * Since each LLM has it's own way of managing history, provide an API
 * that can be used in "llm.js" when implementing one or another LLM.
 *
 * Only "llm.js" should write to history (setHistory and ResetHistory). The
 * UI code should only read the history ("getHistory")
 *
 * "History" is an array of "messages".
 *
 * A "message" is an object containing:
 *   - "sender": "coach" or "tutor"
 *   - "message" : string
 */

let history = [];

export function addToHistory(sender, message) {
  history.push(buildHistoryMessage(sender, message));
  return history;
}

export function resetHistory(messages = []) {
  history = messages;
  return history;
}

export function getHistory() {
  return history;
}

export function buildHistoryMessage(sender, message) {
  return {
    sender: sender,
    message: message,
  };
}

export function setHistory(newHistory) {
  history = newHistory;
  return history;
}

export function initializeHistory() {
  setHistory(initialHistoryMessage);
}

/*** PRIVATE CONSTANTS ***/

const initialHistoryMessage = [
  {
    sender: "coach",
    message: "Please provide all of your output in JSON format.",
  },
];
