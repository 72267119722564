/***
 * Configuring the coachforge-engine
 *
 * Example usage:
 *
 * import { configureLibrary } from 'your-library';
 *
 * configureLibrary({
 *   baseUrl: process.env.REACT_APP_COACHFORGE_URL,
 *   apiKey: process.env.REACT_APP_COACHFORGE_KEY,
 *   enabled: process.env.REACT_APP_USE_LLM,
 *   model: process.env.REACT_APP_OPENAI_MODEL,
 * });
 *
 */

let config = {};

export function configureCoachforgeEngine(newConfig) {
  if (typeof newConfig !== "object" || newConfig === null) {
    throw new Error("Configuration must be an object");
  }

  // Validate and set each config key
  Object.entries(newConfig).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      throw new Error(
        `Configuration value for '${key}' cannot be null or undefined`
      );
    }
    config[key] = value;
  });
}

export function getConfig(key) {
  if (typeof key !== "string") {
    throw new Error("Config key must be a string");
  }

  if (!(key in config)) {
    throw new Error(`Configuration key '${key}' not found`);
  }

  return config[key];
}

// Optional: Helper function to check if the library is configured
export function isConfigured() {
  return Object.keys(config).length > 0;
}

// Optional: Function to reset configuration (useful for testing)
export function resetConfig() {
  config = {};
}
