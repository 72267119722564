import React from "react";
import Logout from "components/Logout";

const NavBar = ({ logout = true }) => {
  const title = process.env.REACT_APP_TITLE;
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img
          src="/assets/coachforge.png"
          alt="Dashboard Icon"
          className="icon"
        />
        <div className="title">{title}</div>
      </div>
      {logout && <Logout />}
    </nav>
  );
};

export default NavBar;
