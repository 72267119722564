// Import features
import { useContext } from "react";
import { appHierarchy } from "@chipcleary/coachforge-engine";

// Import components
import conversationStateContext from "components/ConversationStateContext";
import ConversationStateElement from "components/ConversationStateElement";
import SelectAppModal from "components/SelectAppModal";
import {
  RestartRequestsButton,
  RerunCurrentRequestButton,
  RunNextRequestButton,
  FastForwardRequestsButton,
} from "./RequestButton";

export default function NavigationPanel() {
  const [conversationState] = useContext(conversationStateContext);

  const hierarchy = ["activity", "task", "request"];

  if (conversationState) {
    return (
      <div className="control-panel navigation-panel">
        <div className="title">
          <SelectAppModal />
          <div className="navigation-title-container">
            <span className="icon-group">
              <RestartRequestsButton />
              <RerunCurrentRequestButton />
            </span>
            <div className="title">Choose Request</div>
            <span className="icon-group">
              <RunNextRequestButton />
              <FastForwardRequestsButton></FastForwardRequestsButton>
            </span>
          </div>
        </div>
        <div className="body">
          <div className="program-position">
            {hierarchy.map((level) => (
              <ConversationStateElement key={level} level={level} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="control-panel navigation-panel">
      <h3>Choose Request</h3>
      <div className="program-position">
        {appHierarchy().map((level) => (
          <span>`${level}: -</span>
        ))}
      </div>
    </div>
  );
}
