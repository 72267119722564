import AgentResponseCard from "components/AgentResponseCard";
import ModelResponseCard from "components/ModelResponseCard";

export default function AgentPanel() {
  return (
    <div className="dashboard-panel tutor-panel">
      <AgentResponseCard />
      <ModelResponseCard />
    </div>
  );
}
