import ConversationStateContext from "components/ConversationStateContext";
import { useContext } from "react";

// import Card from "components/Card";
import { Card } from "@chipcleary/cht-react-ui-tools";
import { getCurrentModelResponse } from "@chipcleary/coachforge-engine";

export default function ModelResponseCard() {
  const [conversationState] = useContext(ConversationStateContext);

  return (
    <Card title="Model Response">
      {getCurrentModelResponse(conversationState)}
    </Card>
  );
}
