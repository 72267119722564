import { getConfig } from "../config";

/***
 * Fetch JSON
 ***/
export async function fetchJsonUsingGet(endpoint) {
  const response = await fetchUsingGet(endpoint);
  return await response.json();
}

export async function fetchJsonUsingPost(endpoint, body) {
  const response = await fetchUsingPost(endpoint, body);
  return await response.json();
}

/***
 * Fetch text
 ***/
export async function fetchTextUsingGet(endpoint) {
  const response = await fetchUsingGet(endpoint);
  return await response.text();
}

export async function fetchTextUsingPost(endpoint, body) {
  const response = await fetchUsingPost(endpoint, body);
  return await response.text();
}

/***
 * Support functions
 ***/
async function fetchUsingGet(endpoint) {
  const url = getConfig("baseUrl") + "/" + endpoint;

  const response = await fetch(url, {
    method: "GET",
    headers: buildFetchHeaders(),
  });

  return response;
}

async function fetchUsingPost(endpoint, body) {
  const url = getConfig("baseUrl") + "/" + endpoint;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": getConfig("apiKey"),
      "Cache-Control": "no-cache",
    },
    body: JSON.stringify(body),
  });
  return response;
}

function buildFetchHeaders() {
  return {
    "api-key": getConfig("apiKey"),
    "Cache-Control": "no-cache",
  };
}
