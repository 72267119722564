// Import functions
import { useContext, useEffect, useState } from "react";
import { buildRequest } from "@chipcleary/coachforge-engine";
import { isEmpty } from "utils/utils";

// Import components
import conversationStateContext from "components/ConversationStateContext";
import { Card } from "@chipcleary/cht-react-ui-tools";

export default function CoachRequestCard() {
  const [conversationState] = useContext(conversationStateContext);
  const [requestText, setRequestText] = useState("");

  useEffect(() => {
    async function getContents() {
      updateRequestText();
    }
    getContents();
    // eslint: Disable b/c we don't want to update when userInput changes.
  }, [conversationState, conversationState.persona]); // eslint-disable-line

  return (
    <Card title="Coach Request">
      <pre>{requestText}</pre>
    </Card>
  );

  /*** Support functions ***/
  async function updateRequestText() {
    const userInput = conversationState.userInput;

    // If there's no request ...
    if (isEmpty(conversationState.request)) {
      setRequestText("<request not selected>");

      // If there's no userInput
    } else if (!userInput) {
      setRequestText("Loading ...");
      const inputs = { userInput: "<persona not selected>" };
      const instructions = await buildRequest(
        conversationState,
        inputs,
        "author"
      );
      setRequestText(instructions);

      // We're good to load
    } else {
      setRequestText("Loading ...");
      const inputs = { userInput: userInput };
      const instructions = await buildRequest(
        conversationState,
        inputs,
        "author"
      );
      setRequestText(instructions);
    }
  }
}
