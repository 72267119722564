// Import components
import UserTaskCard from "components/UserTaskCard";
import UserInputCard from "components/UserInputCard";

export default function UserPanel() {
  return (
    <div className="dashboard-panel user-panel">
      <UserTaskCard />
      <UserInputCard />
    </div>
  );
}
