// Import styles
import "css/style.css";
import "@chipcleary/cht-react-ui-tools/dist/styles.css";

// Import functions
import React from "react";
import { useEffect, useState } from "react";
import {
  buildPosition,
  configureCoachforgeEngine,
  initializeConversationState,
  initializeMessages,
} from "@chipcleary/coachforge-engine";

// Import components
import conversationStateContext from "components/ConversationStateContext";
import AppRouter from "components/AppRouter";
import { LoadingScreen } from "@chipcleary/cht-react-ui-tools";

export default function App() {
  const [conversationState, setConversationState] = useState({
    position: buildPosition(0, 0, 0, 0),
    priorResults: {},
  });
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function initialize() {
      // Initialize CoachforgeEngine
      configureCoachforgeEngine({
        baseUrl: process.env.REACT_APP_COACHFORGE_URL,
        apiKey: process.env.REACT_APP_COACHFORGE_KEY,
        enabled: process.env.REACT_APP_USE_LLM,
        model: process.env.REACT_APP_OPENAI_MODEL,
      });

      // Initialize messages
      await initializeMessages();

      // Initialize conversationsState
      if (!conversationState.app) {
        const newConversationState = await initializeConversationState();
        setConversationState(newConversationState);
      }

      // App is now loaded
      setInitialized(true);
    }
    initialize();
  }, []); // eslint-disable-line

  if (initialized && conversationState.app) {
    return (
      <div className="App">
        <conversationStateContext.Provider
          value={[conversationState, setConversationState]}
        >
          <AppRouter />
        </conversationStateContext.Provider>
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
}
