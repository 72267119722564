import { getParent } from "@chipcleary/coachforge-engine";
import { titleCase } from "utils/utils";
import { useContext } from "react";

// Import components
import ConversationStateDropdown from "components/ConversationStateDropdown";
import ConversationStateContext from "components/ConversationStateContext";

export default function ConversationStateElement({ level }) {
  const [conversationState] = useContext(ConversationStateContext);

  return (
    <div className="position-element">
      <div className="position">{buildLabel(level)}</div>
      <ConversationStateDropdown key={level} level={level} />
    </div>
  );

  function buildLabel(level) {
    return `${titleCase(level)} ${buildProgressCount(level)}`;
  }

  function buildProgressCount(level) {
    const parent = getParent(level);
    const count = conversationState[parent]["children"].length;
    return count
      ? `(${conversationState.position[level] + 1} of ${count})`
      : "(None Available)";
  }
}
