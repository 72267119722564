import AuthService from "services/AuthService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

export default function Logout() {
  const navigate = useNavigate();

  function handleLogout() {
    AuthService.logout();
    navigate("/login");
  }

  //  return <button onClick={handleLogout}>Logout</button>;

  return (
    <button
      className="icon-button title-bar-button logout-button"
      onClick={handleLogout}
    >
      {" "}
      <FontAwesomeIcon icon={faSignOut} />{" "}
    </button>
  );
}
