/*
 * Define the structure of a catalog
 */

const hierarchy = new Map([
  ["catalog", null],
  ["app", "catalog"],
  ["activity", "app"],
  ["task", "activity"],
  ["request", "task"],
]);

export function appHierarchy() {
  return Array.from(hierarchy.keys());
}

export function getParent(level) {
  return hierarchy.get(level);
}

export function getPlural(level) {
  return level === "activity" ? "activities" : level + "s";
}

export function getChildren(level) {
  const keys = appHierarchy();
  const index = keys.indexOf(level);
  return keys.splice(index + 1);
}
