// import functions
import React from "react";
import { getHistory } from "@chipcleary/coachforge-engine";

// import components
import { Overlay } from "@chipcleary/cht-react-ui-tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimeline } from "@fortawesome/free-solid-svg-icons";
import HistoryList from "components/HistoryList";

export function HistoryOverlay({ onClick }) {
  return (
    <Overlay className="history-overlay" onClick={onClick} title="History">
      <HistoryList history={getHistory()} />
    </Overlay>
  );
}

export function HistoryItem({ sender, message }) {
  const senderClassName = "history-item-" + sender;
  const text =
    typeof myVar === "string" ? message : JSON.stringify(message, null, 2);
  return (
    <div className={`history-item ${senderClassName}`}>
      <pre>
        <p className="role">{sender}</p>
        <TextFormatter className="message" text={text} />
      </pre>
    </div>
  );
}

export function ShowHistoryButton({ onClick }) {
  return (
    <button
      className="icon-button title-bar-button show-history-button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimeline} />
    </button>
  );
}

function TextFormatter({ className, text }) {
  const formattedText = convertMarkup(stripQuotes(text));
  return (
    <pre
      className={className}
      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
    >
      {formattedText}
    </pre>
  );
}

function convertMarkup(text) {
  const formattedText = text
    .replace(/\\r/g, " ") // Convert \r to a space
    .replace(/\\n/g, "\n") // Convert \n to actual line breaks
    .replace(/\\"/g, '"') // Convert \" to "
    .replace(/&lt;/g, "<") // Convert &lt; to <
    .replace(/&gt;/g, ">"); // Convert &gt; to >
  return formattedText;
}

function stripQuotes(text) {
  const formattedText = text.replace(/^"?\s*/, "").replace(/\s*"?$/, "");
  return formattedText;
}
