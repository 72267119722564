// Import components
import CoachRequestCard from "components/CoachRequestCard";
import NavigationPanel from "components/NavigationPanel";
import UserPanel from "components/UserPanel";
import AgentPanel from "components/AgentPanel";
import NavBar from "components/Navbar";

export default function Dashboard() {
  return (
    <div className="dashboard">
      <NavBar />
      <div className="control">
        <NavigationPanel />
      </div>
      <div className="content">
        <UserPanel />
        <CoachRequestCard />
        <AgentPanel />
      </div>
    </div>
  );
}
