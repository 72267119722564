import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/AuthService";
import Navbar from "components/Navbar";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await AuthService.login(username, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const authorUrl = process.env.REACT_APP_COACHFORGE_URL;
  return (
    <form className="login" onSubmit={handleLogin}>
      <Navbar logout={false} />
      <div className="form-body">
        <div className="heading">
          <h3>Login</h3>
          <div>
            Sign up for an account at &nbsp;
            <a href={authorUrl}>CoachForge Author</a>
          </div>
        </div>
        <div className={"input-container"}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="input-box"
          />
        </div>
        <div className={"input-container"}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="input-box"
          />
        </div>
        <div className="actions">
          <button className="btn btn-light" type="submit">
            Go
          </button>
        </div>
      </div>
    </form>
  );
}
