import axios from "axios";

const API_URL =
  process.env.REACT_APP_COACHFORGE_URL +
  "/" +
  process.env.REACT_APP_COACHFORGE_OAUTH_API;
const CLIENT_ID = process.env.REACT_APP_COACHFORGE_OAUTH_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_COACHFORGE_OAUTH_CLIENT_SECRET;

const AuthService = {
  login: async (username, password) => {
    const response = await axios.post(API_URL, {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: username,
      password: password,
    });
    if (response.data.access_token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  },

  logout: () => {
    localStorage.removeItem("user");
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem("user"));
  },
};

// Make AuthService available globally for testing
window.AuthService = AuthService;

export default AuthService;
