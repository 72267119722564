// Import functions
import { useState } from "react";

// Import components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export default function ChatMessageInput({ onSubmit }) {
  const [input, setInput] = useState("");
  const onChange = (e) => setInput(e.target.value);
  const checkForSubmit = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      onSubmit(input);
      setInput("");
      e.preventDefault(); // Prevents adding a new line in the text field
    }
  };
  return (
    <div className="input">
      <textarea
        type="text"
        rows="3"
        aria-label="chat message input"
        value={input}
        onKeyDown={checkForSubmit}
        onChange={onChange}
      />
      <SubmitInputButton isDisabled={!input} />
    </div>
  );

  function SubmitInputButton({ isDisabled }) {
    async function clickAction() {
      onSubmit(input);
      setInput("");
    }

    return (
      <button
        className="icon-button submit-input-button"
        onClick={clickAction}
        disabled={isDisabled}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
    );
  }
}
