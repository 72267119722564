/*
 * Support for "tokens", that is, variables in the input messages to be filled from
 * content maintained by the frontend.
 */
import { getPathIds } from "./sharedFunctions";
import { getPriorResult, recastPriorResult } from "./priorResults";

/*
 * Interface to rest of engine
 */

export function replaceTokens(text, conversationState, inputs) {
  text = replaceInputTokens(text, inputs);
  text = replaceHistoryTokens(text, conversationState);
  text = replaceExtendedHistoryTokens(text, conversationState);
  return text;
}

/*
 * Token replacement functions
 */

function replaceInputTokens(text, inputs) {
  const regex = /\$\{input\s+(\w+)\}/g;
  function tokenProcessor(key) {
    return key in inputs ? inputs[key] : "*** Key not found in inputs ***";
  }
  return processTokens(text, regex, tokenProcessor);
}

// History tokens require a reduced path (request only)
function replaceHistoryTokens(text, conversationState) {
  const regex = /\$\{history\s+([\d.]+\w+)\}/g;
  function tokenProcessor(reducedPath) {
    const ids = getPathIds(conversationState);
    // Add activity and task to build the full path
    const fullPath = `${ids[1]}.${ids[2]}.${reducedPath}`;
    const uncastResult = getPriorResult(fullPath, conversationState);
    return recastPriorResult(uncastResult, fullPath);
  }
  return processTokens(text, regex, tokenProcessor);
}

// Extended History tokens require a full path (activity, task, request)
function replaceExtendedHistoryTokens(text, conversationState) {
  const regex = /\$\{extended\s+([\d.]+\w+)\}/g;
  function tokenProcessor(path) {
    const uncastResult = getPriorResult(path, conversationState);
    return recastPriorResult(uncastResult, path);
  }
  return processTokens(text, regex, tokenProcessor);
}

/*
 * Utilities
 */

/*
 * Returns a string in which the "tokens" in inputString are replaced by
 * calling "tokenProcessor" on them.
 *
 * "tokenProcessor" takes a string input (the token) and always return a string
 */
function processTokens(inputString, regex, tokenProcessor) {
  return inputString.replace(regex, (match, value) => {
    return tokenProcessor(value);
  });
}
