// Import functions
import { getResponse } from "@chipcleary/coachforge-engine";
import { useContext, useEffect, useState } from "react";
import { isEmpty } from "utils/utils";

// Import components
import conversationStateContext from "components/ConversationStateContext";
import { Card } from "@chipcleary/cht-react-ui-tools";

import {
  PriorResultsOverlay,
  ShowPriorResultsButton,
} from "components/PriorResultsOverlay";
import { ChatOverlay, ShowChatButton } from "components/ChatOverlay";

export default function AgentResponseCard() {
  const [conversationState] = useContext(conversationStateContext);
  const [responseText, setResponseText] = useState("");
  const [showPriorResults, setShowPriorResults] = useState(false);
  const [showChat, setShowChat] = useState(false);

  // When conversationState changes
  useEffect(() => {
    async function getContents() {
      updateContents();
    }
    getContents();
  }, [conversationState, conversationState.userReponse]); // eslint-disable-line

  return (
    <div>
      <Card title={<AgentTitle />}>
        <pre>{responseText}</pre>
      </Card>
      {showPriorResults && (
        <PriorResultsOverlay
          priorResults={conversationState.priorResults}
          onClick={toggleHistory}
        />
      )}
      {showChat && <ChatOverlay onClick={toggleChat} />}
    </div>
  );

  function AgentTitle() {
    return (
      <div className="title">
        <ShowChatButton onClick={toggleChat} />
        <span>Agent Response</span>
        <ShowPriorResultsButton onClick={toggleHistory} />
      </div>
    );
  }

  /*** Support functions ***/
  async function updateContents() {
    const userInput = conversationState.userInput;

    // If there's no request ...
    if (isEmpty(conversationState.request)) {
      setResponseText("<request not selected>");

      // If there's no userInput
    } else if (!userInput) {
      setResponseText("<persona not selected>");

      // Else we're good to load
    } else {
      setResponseText("Loading ...");
      const inputs = { userInput: userInput };
      const response = await getResponse(conversationState, inputs);
      setResponseText(JSON.stringify(response, null, 2));
    }
  }

  function toggleHistory() {
    setShowPriorResults(!showPriorResults);
  }

  function toggleChat() {
    setShowChat(!showChat);
  }
}
