/*
 * Generate messages as input to the LLM
 *
 * Maybe call "prompt.js"?
 */

import { fetchTextUsingGet, fetchTextUsingPost } from "./fetchUtils";

import { getPathIds } from "./sharedFunctions";
import { replaceTokens } from "./token";

/*** INTERFACE TO COACHFORGE ***/

export async function buildLevel(level, conversationState) {
  const spec = conversationState[level];
  const endpoint = `api/v1/${level}/${spec.id}/agent`;

  return await buildMessage(endpoint, conversationState);
}

export async function buildCoach(conversationState) {
  const endpoint = `api/v1/coach`;
  const body = {
    path: getPathIds(conversationState),
  };

  return await fetchTextUsingPost(endpoint, body);
}

export async function buildRequest(
  conversationState,
  inputs,
  display = "agent"
) {
  const request = conversationState.request;

  if (request) {
    const endpoint = `api/v1/request/${request.id}/${display}`;
    return await buildMessage(endpoint, conversationState, inputs);
  }

  return "<no request selected>";
}

export async function buildMessage(endpoint, conversationState, inputs = {}) {
  var text = await fetchTextUsingGet(endpoint);
  return replaceTokens(text, conversationState, inputs);
}

/*** INTERFACE TO TEST APP  ***/

export async function initializeMessages() {
  // no work to be done.
}
