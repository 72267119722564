/*
 * CRUD operates on messageSpecs
 *
 * messageSpecs are js objects that are processed by templates to produce messages
 */
import { getParent } from "./appHierarchy";
import { fetchJsonUsingGet, fetchTextUsingGet } from "./fetchUtils";

export async function retrieveMessageSpec(level, conversationState) {
  console.log("(retrieveMessageSpec): hi");
  console.log("level: " + level);
  const parentName = getParent(level);
  console.log("parentName: " + parentName);
  const parent = conversationState[parentName];
  console.log("parent: ");
  console.log(parent);
  // if parent doesn't exist, return empty object
  if (!parent) {
    return {};
  }

  // parent does exist, check that child exists. If not, return an empty object
  const index = conversationState.position[level];
  if (!(parent["children"] && parent["children"][index])) {
    console.log("retrieveMessageSpec: whoops 1");
    return {};
  }
  const id = parent["children"][index];
  console.log("(retrieveMessageSpec) id: " + id);
  const messageSpec = await fetchMessageSpec(id);
  console.log("retrieveMessageSpec: returning ...");
  console.log(messageSpec);

  return messageSpec;
}

export async function fetchMessageSpec(id) {
  const endpoint = `api/v1/message-spec/${id}`;
  const data = await fetchJsonUsingGet(endpoint);
  return data;
}

// Given a list of message spec ids, generate the titles of these specs.
export async function getSpecTitles(ids) {
  const names = await Promise.all(ids.map(fetchMessageSpecTitle));
  return names;
}

export async function fetchMessageSpecTitle(nid) {
  const endpoint = `api/v1/node-title/${nid}`;
  return await fetchTextUsingGet(endpoint);
}
