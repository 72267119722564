import { useContext, useEffect, useState } from "react";
import {
  getChildren,
  getSiblings,
  getSpecTitles,
  loadPosition,
} from "@chipcleary/coachforge-engine";

// Import components
import ConversationStateContext from "components/ConversationStateContext";
import { Dropdown } from "@chipcleary/cht-react-ui-tools";

export default function ConversationStateDropdown({ level }) {
  const [conversationState, setconversationState] = useContext(
    ConversationStateContext
  );
  const [siblingTitles, setSiblingTitles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load the options
  useEffect(() => {
    async function loadSiblingNames() {
      const ids = await getSiblings(level, conversationState);
      const titles = await getSpecTitles(ids);
      setSiblingTitles(titles);
      setIsLoading(false);
    }
    loadSiblingNames();
  }, [conversationState]); // eslint-disable-line

  // Return disabled stub if still loading or no siblings
  if (isLoading || !conversationState[level]) {
    return <Dropdown key={level} disabled={true} options={[]} />;
  }

  // Siblings are loading and the conversation exists
  const currentIndex = conversationState.position[level];
  return (
    <Dropdown
      key={level}
      label={level}
      value={currentIndex}
      options={siblingTitles.map((title, index) => ({
        label: title,
        value: index,
      }))}
      onChange={handleChange}
    />
  );

  async function handleChange(event) {
    const newPosition = setConversationStateLevel(
      level,
      event.target.value,
      conversationState.position
    );
    const newConversationState = await loadPosition(
      newPosition,
      conversationState
    );
    setconversationState(newConversationState);
  }

  function setConversationStateLevel(level, index, position) {
    // Set the position to the chosen index
    position[level] = Number(index);
    // Reset all the children to position 0
    getChildren(level).forEach((child) => (position[child] = 0));
    return position;
  }
}
