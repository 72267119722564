// Import components
import { HistoryItem } from "components/HistoryOverlay";

export default function HistoryList({ history }) {
  return (
    <div className="history">
      {history.map((item, index) => {
        return (
          <HistoryItem
            key={index}
            sender={item.sender}
            message={item.message}
          />
        );
      })}
    </div>
  );
}
