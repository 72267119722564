import { getConfig } from "../config";
import { buildCoach, buildLevel, buildRequest } from "./message";
import { addToHistory, getHistory, resetHistory } from "./history";
import { storePriorResult } from "./priorResults";
import { fetchJsonUsingPost } from "./fetchUtils";

/*** INTERFACE TO THE REST OF THE PROGRAM ***/

export async function getResponse(conversationState, inputs) {
  // Reset the full history on each request
  await buildStartingPoint(conversationState, inputs);
  const chatgptMessages = getHistory().map(toChatgptMessage);

  // Get the response
  let response = await retrieveResponse(chatgptMessages);

  // Store the response in priorResults
  storePriorResult(conversationState, inputs, response);

  // Add the response to the history
  addToHistory("tutor", response);

  // And then return it.
  return response;
}

// get a response while leaving the prior history intact
// This assumes that the "calling" function manages the history.
export async function getCumulativeResponse() {
  const chatgptMessages = getHistory().map(toChatgptMessage);
  // TODO: When I reimplement, should I "addToHistory("tutor", response);"
  return await retrieveResponse(chatgptMessages);
}

/*** SUPPORT FUNCTIONS ***/

export async function retrieveResponse(chatGptMessages) {
  if (getConfig("enabled")) {
    try {
      return await retrieveUsingAuthorForge(chatGptMessages);
    } catch (error) {
      console.error("Error making request to OpenAI:", error);
      return null;
    }
  }
  return '{ "response": "<dummy response>" }';
}

async function retrieveUsingAuthorForge(messages) {
  // TODO: Move to an env variable
  const endpoint = "api/v1/openai/complete";
  const body = {
    messages: messages,
    model: getConfig("model"),
  };

  return await fetchJsonUsingPost(endpoint, body);
}

async function buildStartingPoint(conversationState, inputs) {
  resetHistory();
  addToHistory("coach", await buildLevel("app", conversationState));
  addToHistory("coach", await buildCoach(conversationState));
  addToHistory("coach", await buildLevel("activity", conversationState));
  addToHistory("coach", await buildLevel("task", conversationState));
  addToHistory("coach", await buildRequest(conversationState, inputs));
}

/*** INTERFACE TO HISTORY.JS ***/

export function toChatgptMessage(historyMessage) {
  return {
    role: historyMessage.sender === "tutor" ? "assistant" : "user",
    content: historyMessage.message,
  };
}

/*
function toHistoryMessage(chatgptMessage) {
  return {
    sender: chatgptMessage.role === "assistant" ? "tutor" : "coach",
    message: chatgptMessage.content,
  };
}
*/
