import React, { useContext, useEffect, useState } from "react";
import ConversationStateContext from "components/ConversationStateContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { fetchMessageSpecTitle, loadApp } from "@chipcleary/coachforge-engine";

export default function SelectAppModal() {
  const [conversationState, setConversationState] = useContext(
    ConversationStateContext
  );
  const [selectedIndex, setSelectedIndex] = useState("");
  const [apps, setApps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load the options
  useEffect(() => {
    async function loadApps() {
      const ids =
        conversationState.catalog && conversationState.catalog.children;
      var apps = [];
      for (const id of ids) {
        const title = await fetchMessageSpecTitle(id);
        apps.push({ id: id, title: title });
      }
      setApps(apps);
      setIsLoading(false);
    }
    loadApps();
  }, []); // eslint-disable-line

  if (isLoading) {
    return "";
  }

  return (
    <Popup
      trigger={
        <button className="icon-button title-bar-button float-left-button select-app-button">
          <FontAwesomeIcon icon={faFolder} />{" "}
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="modal select-app-modal">
          <div className="title">Select an Apps</div>
          <div className="body">
            <select
              value={selectedIndex}
              onChange={(e) => setSelectedIndex(e.target.value)}
            >
              {apps.map((app, index) => (
                <option key={index} value={index}>
                  {app.title}
                </option>
              ))}
            </select>
          </div>
          <div className="actions">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleSelect();
                close();
              }}
            >
              Select
            </button>
            <button
              className="btn"
              onClick={() => {
                handleCancel();
                close();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );

  /*** Handler functions ***/

  async function handleSelect() {
    updateApp();
  }

  async function updateApp() {
    const newConversationState = await loadApp(
      selectedIndex,
      conversationState
    );
    setConversationState(newConversationState);
  }

  function handleCancel() {}
}
