// import functions
import React from "react";

// import components
import { Overlay } from "@chipcleary/cht-react-ui-tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";

export function PriorResultsOverlay({ priorResults, onClick }) {
  return (
    <Overlay
      className="prior-results-overlay"
      onClick={onClick}
      title="Prior Results"
    >
      <pre>{JSON.stringify(priorResults, null, "  ")}</pre>
    </Overlay>
  );
}

export function ShowPriorResultsButton({ onClick }) {
  return (
    <button
      className="icon-button title-bar-button show-prior-results-button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faRectangleList} />
    </button>
  );
}
