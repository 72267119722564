// Import functions
import { useContext, useEffect, useState } from "react";
import { isEmpty } from "utils/utils";

// Import components
import conversationStateContext from "components/ConversationStateContext";
//import Card from "components/Card";
import { Card } from "@chipcleary/cht-react-ui-tools";

export default function UserTaskCard() {
  const [conversationState] = useContext(conversationStateContext);
  const [taskText, setTaskText] = useState("");

  useEffect(() => {
    async function getContents() {
      updateTaskText();
    }
    getContents();
    // eslint: Disable b/c we don't want to update when userInput changes.
  }, [conversationState, conversationState.task]); // eslint-disable-line

  return (
    <Card title="User Task">
      <pre>{taskText}</pre>
    </Card>
  );

  /*** Support functions ***/
  async function updateTaskText() {
    const task = conversationState.task;

    // If there's no task
    if (isEmpty(conversationState.task)) {
      setTaskText("<task not selected>");

      // If there's no task description
    } else if (!task.description) {
      setTaskText("<task does not provide a description>");

      // We're good to load
    } else {
      setTaskText(task.description);
    }
  }
}
