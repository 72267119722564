import React, { useContext, useEffect, useState } from "react";
import { getPersonas } from "@chipcleary/coachforge-engine";
import ConversationStateContext from "components/ConversationStateContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";

export default function SelectPersonaDialog() {
  const [conversationState, setConversationState] = useContext(
    ConversationStateContext
  );
  const [selectedPersonaId, setSelectedPersonaId] = useState(
    getInitialPersonaId()
  );
  const [personas, setPersonas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load the options
  useEffect(() => {
    async function loadPersonas() {
      const data = await getPersonas(conversationState);
      setPersonas(data);
      setIsLoading(false);
    }
    loadPersonas();
  }, [conversationState.app]); // eslint-disable-line

  if (isLoading) {
    return "Loading ...";
  }

  return (
    <Popup
      trigger={
        <button className="icon-button title-bar-button float-left-button select-app-button">
          <FontAwesomeIcon icon={faInbox} />{" "}
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="modal select-persona-modal">
          <div className="title">Select a Persona</div>
          <div className="body">
            <select
              value={selectedPersonaId}
              onChange={(e) => setSelectedPersonaId(e.target.value)}
            >
              {personas.map((persona) => (
                <option key={persona.id} value={persona.id}>
                  {persona.title}
                </option>
              ))}
            </select>
          </div>
          <div className="actions">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleSelect();
                close();
              }}
            >
              Select
            </button>
            <button
              className="btn"
              onClick={() => {
                handleCancel();
                close();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );

  /*** Handler functions ***/

  async function handleSelect() {
    // If user hasn't selected a persona yet, choose the first one if one exists
    if (!selectedPersonaId) {
      if (personas) {
        const persona = personas[0];
        setSelectedPersonaId(persona.id);
        updatePersona(persona);
      }
    } else {
      const persona = personas.find((p) => p.id === selectedPersonaId);
      updatePersona(persona);
    }
  }

  function handleCancel() {}

  /*** Support functions ***/

  async function updatePersona(persona) {
    await setConversationState((prevState) => ({
      ...prevState,
      persona: persona,
    }));
  }

  function getInitialPersonaId() {
    return conversationState.persona ? conversationState.persona.id : "";
  }
}
