// Import functions
import { useState } from "react";
import {
  addToHistory,
  getCumulativeResponse,
  getHistory,
} from "@chipcleary/coachforge-engine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

// Import components
import { Overlay } from "@chipcleary/cht-react-ui-tools";
import ChatMessageInput from "components/ChatMessageInput";
import HistoryList from "components/HistoryList";

export function ChatOverlay({ onClick }) {
  const [localHistory, setLocalHistory] = useState(getHistory());

  return (
    <Overlay
      className="chat-overlay"
      onClick={onClick}
      title="Chat with the Tutor"
    >
      <HistoryList history={localHistory} />
      <ChatMessageInput onSubmit={processInput} />
    </Overlay>
  );

  async function processInput(input) {
    addToHistory("coach", input);
    var history = getHistory();
    setLocalHistory([...history]); // make a new copy so that react re-renders
    const response = await getCumulativeResponse(input);
    addToHistory("tutor", response);
    history = getHistory();
    setLocalHistory([...history]);
  }
}

export function ShowChatButton({ onClick }) {
  return (
    <button
      className="icon-button title-bar-button float-left-button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faComments} />
    </button>
  );
}
