import { getPathIds } from "./sharedFunctions";

/*** PRIOR RESULTS API ***/

export function clearPriorResults(conversationState) {
  conversationState.priorResults = {};
}

export function storePriorResult(conversationState, inputs, result) {
  var ids = getPathIds(conversationState);
  ids.shift(); // discard the "app"

  // Ensure the path is populated
  populatePath(conversationState, ids);

  // Store the input inside the task
  conversationState.priorResults[ids[0]][ids[1]]["inputs"] = inputs;

  // Store the request title inside the request
  conversationState.priorResults[ids[0]][ids[1]][[ids[2]]].title =
    conversationState.request.title;

  // Store the result inside the request
  conversationState.priorResults[ids[0]][ids[1]][[ids[2]]] = result;
}

// Returns the value found using the specified path.
// Returns an undefined object if path doesn't lead to a result.
export function getPriorResult(path, conversationState) {
  const props = path.split(".");
  let value = conversationState.priorResults;
  for (const prop of props) {
    value = value[prop];
    if (!value) {
      break;
    }
  }
  return value;
}

/*** PRIOR RESULTS SUPPORT FUNCTIONS ***/

// Add objects as necessary to ensure the path is populated.
// Note: we assume the app is constant and it is ignored when
// populating the path
function populatePath(conversationState, ids) {
  var pathLocation = conversationState.priorResults;
  for (const id of ids) {
    if (!pathLocation[id]) {
      pathLocation[id] = {};
    }
    pathLocation = pathLocation[id];
  }
}

// Always returns a string.
export function recastPriorResult(uncastResult, pathString) {
  var result = "";
  switch (typeof uncastResult) {
    // Acceptable "as is"
    case "string":
      result = uncastResult;
      break;
    // Acceptable when cast to string
    case "number":
      result = uncastResult.toString();
      break;
    // Error
    case "undefined":
      result = `<ERROR (replaceTokens): no result found for path "${pathString}">`;
      break;
    // Error
    default:
      result = `<ERROR (replaceTokens): Path "${pathString}" generates an unexpected result of type ${typeof uncastResult}>`;
  }
  return result;
}
