/*
 * This file exists to avoid a circular dependency between conversationState and priorResults
 */

import { getParent } from "./appHierarchy";

export function getCurrentId(level, conversationState) {
  const parentLevel = getParent(level);
  const parent = conversationState[parentLevel];
  if (parent) {
    const index = conversationState.position[level];
    return parent["children"][index];
  }
  return "";
}

// Return an array of message IDs representing the current path
export function getPathIds(conversationState) {
  const ids = [
    getCurrentId("app", conversationState),
    getCurrentId("activity", conversationState),
    getCurrentId("task", conversationState),
    getCurrentId("request", conversationState),
  ];
  return ids;
}
