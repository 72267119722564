// Import functions
import { useContext, useEffect, useState } from "react";
import { getCurrentUserInput } from "@chipcleary/coachforge-engine";

// Import components
import ConversationStateContext from "components/ConversationStateContext";
//import Card from "components/Card";
import { Card } from "@chipcleary/cht-react-ui-tools";
import SelectPersonaDialog from "components/SelectPersonaDialog";

export default function UserInputCard() {
  const [conversationState, setConversationState] = useContext(
    ConversationStateContext
  );
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    async function getContents() {
      updateInputText(setUserInput, conversationState, setConversationState);
    }
    getContents();
    // eslint: Disable b/c we don't want to update when userInput changes.
  }, [conversationState.persona, conversationState.task]); // eslint-disable-line

  return (
    <Card title={<UserInputTitle />}>
      {userInput ? userInput : "<persona not selected>"}
    </Card>
  );
}

/*** Children components ***/

function UserInputTitle() {
  const [conversationState] = useContext(ConversationStateContext);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const personaTitle =
      conversationState.persona && conversationState.persona.title;
    setTitle(personaTitle ? `${personaTitle}'s Input` : "Select a Persona");
  }, [conversationState.persona]);

  return (
    <div className="title">
      <SelectPersonaDialog />
      <span>{title}</span>
      <div className="icon-button title-bar-button transparent-button float-right-button"></div>
    </div>
  );
}

/*** Support functions ***/

async function updateInputText(
  setUserInput,
  conversationState,
  setConversationState
) {
  const newUserInput = await getCurrentUserInput(conversationState);
  updateConversationState(newUserInput, setConversationState);
  setUserInput(newUserInput);
}

function updateConversationState(userInput, setConversationState) {
  setConversationState((prevState) => ({
    ...prevState,
    userInput: userInput,
  }));
}
