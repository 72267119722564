import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "components/Login";
import Dashboard from "components/Dashboard";
import AuthService from "services/AuthService";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

function PrivateRoute({ children }) {
  //  const isAuthenticated = localStorage.getItem("token");
  const isAuthenticated = AuthService.getCurrentUser();
  return isAuthenticated ? children : <Navigate to="/login" />;
}
