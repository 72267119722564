export function titleCase(element) {
  return element.charAt(0).toUpperCase() + element.slice(1);
}

export function isEmpty(object) {
  return Object.keys(object).length === 0;
}

// For debugging
export async function sleep(delay) {
  new Promise((resolve) => setTimeout(resolve, delay));
}
